import React from 'react';

const PayoutStatistics = ({ payoutData, api3TimeStamp }) => {
  return (
    <>
    <div className="two-side-page fade-in">
        <div className="left-column">
          <h2 className="text-light">Payouts Statistics</h2>
          <p className="data-timestamp">Data loaded at: {api3TimeStamp}</p>
        </div>
        <div className="right-column text-right">
          <h3 className="text-light cyberpunk-glow" align="right">Total Amount: {payoutData.reduce((total, payout) => total + Number(payout.amount), 0)}</h3>
          <p className="text-light">Payment will be made after mined Epoche ends in 1 WEEK within 48 hours</p>
        </div>
      </div>


    <div className="card border-light mb-3 fade-in">

      
        <table className="table table-dark table-striped table-hover mb-0">
          <thead>
            <tr>
              <th><span className="text-secondary">Epoch</span></th>
              <th><span className="text-secondary">Transaction ID</span></th>
              <th><span className="text-secondary">Pool</span></th>
              <th><span className="text-secondary">Amount</span></th>
              <th><span className="text-secondary">Status</span></th>
            </tr>
          </thead>
          <tbody>
            {payoutData.map((payout, index) => (
              <tr key={index}>
                <td>{payout.epoch}</td>
                <td><a href={`https://explorer.qubic.org/network/tx/${payout.txid}`} target="_blank" rel="noopener noreferrer">{payout.txid}</a></td>
                <td>{payout.pool}</td>
                <td>{payout.amount}</td>
                <td>{payout.status}</td>             
              </tr>
            ))}
          </tbody>
        </table>  
    </div>
    </>
  );
};

export default PayoutStatistics;
